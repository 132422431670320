<template>
  <div class="b-404">
    <img src="../assets/404页面.png" alt="" />
    <div class="box">
      <p>哎呀！出错了~</p>
      <el-button class="btn" @click="toHome">返回首页 {{ s }}</el-button>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: 'NotFound',
  data () {
    return {
      s: 3
    };
  },
  computed: {
  },
  mounted () {
    this.timer = setInterval(() => {
      this.s--
      if (this.s < 1) {
        clearInterval(this.timer)
        this.toHome()
      }
    }, 1000)
  },
  methods: {
    toHome () {
      this.$router.push('/office')
    }
  }
};
  </script>
  <style lang="less" scoped>
.b-404 {
  background: rgba(245, 245, 245, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .box {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      font-size: 44px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 63.71px;
      background-image: -webkit-linear-gradient(
        top,
        rgba(255, 215, 184, 1),
        rgba(222, 96, 0, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .btn {
      margin-top: 20px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(236, 102, 0, 1);
      font-size: 16px;
      font-weight: 400;
      color: rgba(236, 102, 0, 1);
    }
  }
}
</style>
  